import expireIn from 'lib/redux-persist-transform'
import { combineReducers } from 'redux'
import { getPersistConfig } from 'redux-deep-persist'
import { MigrationManifest, PersistedState } from 'redux-persist'
import createMigrate from 'redux-persist/es/createMigrate'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { ISlotsState } from '../types'
import communications from './communications'
import data from './data'
import { slotsMigrations } from './migrations/migrations'

/**
 * @file Spike.
 * @author Pavel Kurov
 * В общем, slotsMigrations as unknown as MigrationManifest строчка TypeScript костыль, т.к. в redux-persist
 * в этой библиотеке не пофиксили типы https://github.com/rt2zz/redux-persist/issues/1065
 */

const reducedWithComm = combineReducers<ISlotsState>({
  communications,
  data,
})

const expireInValue = 0.5 * 60 * 60 * 1000
const expirationKey = 'expirationKeyTransform'

const persistConfig = getPersistConfig({
  key: 'slots',
  storage,
  version: 6,
  migrate: createMigrate(slotsMigrations as unknown as MigrationManifest, { debug: false }),
  blacklist: ['communications', 'data.gameUrl', 'data.freeSpinInfo', 'data.freeSpinGameUrl'],
  rootReducer: reducedWithComm,
  transforms: [expireIn(expireInValue, expirationKey, [])],
})

export const reducer = persistReducer(persistConfig, reducedWithComm)
