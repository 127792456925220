import { ISlotsStatePersisted } from 'features/slots/types'

type ReturnMigrationType = (state: ISlotsStatePersisted) => ISlotsStatePersisted

export const slotsMigrations: Record<string, ReturnMigrationType> = {
  4: state => ({
    ...state,
    data: {
      ...state.data,
      gameList: [],
    },
  }),
  5: state => ({
    ...state,
    data: {
      ...state.data,
      gameList: [],
    },
  }),
  6: state => ({
    ...state,
    data: {
      ...state.data,
      gameList: [],
    },
  }),
}
